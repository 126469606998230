'use strict';

var $document = $(document),
    $window = $(window),
    $html = $('html'),
    $body = $('body');

var App = {
    init: function() {
        var ref = this;

        // Download links open new tab
        $('.download a').each(function() {
            $(this).attr('target', '_blank');
        });


        /* Scroll-to-top button */

        var offset = 300, // browser window scroll (in pixels) after which the "back to top" link is shown
        offset_opacity = 1200, // browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        scroll_top_duration = 700;
        $back_to_top = $('.scroll-top');

        $(window).scroll(function(){
            ($(this).scrollTop() > offset) ? $back_to_top.addClass('scroll-is-visible') : $back_to_top.removeClass('scroll-is-visible scroll-fade-out');
        });

        $back_to_top.on('click', function(event) {
            event.preventDefault();
            $('body,html').animate({ scrollTop: 0 }, scroll_top_duration);
        });


        /* More button */

        var scroll_more_duration = 400;
        $more_button = $('.hero-carousel__more');

        $more_button.on('click', function(event) {
            var scroll_more_position = $more_button.position().top + $more_button.outerHeight(true) + 1;
            event.preventDefault();
            $('body,html').animate({ scrollTop: scroll_more_position }, scroll_more_duration);
        });
    },
}

$(function() {
    App.init();
});
